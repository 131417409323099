// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.load {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.load .logo-wrapper {
  font-family: Luckiest Guy;
  font-size: 60px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 30px;
}
.load .mainimg {
  width: 300px;
}
.load .car-bottom-heading {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  font-family: Luckiest Guy;
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/components/Mainloader/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,QAAA;EACA,2BAAA;AACF;AACE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,uBAAA;EAAA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EAGA,YAAA;AAFJ","sourcesContent":[".load {\n  display: block;\n  position: relative;\n  top: 50%;\n  transform: translateY(-50%);\n\n  .logo-wrapper {\n    font-family: Luckiest Guy;\n    font-size: 60px;\n    font-weight: 500;\n    display: inline-flex;\n    align-items: center;\n    gap: 30px;\n    // width: 50%;\n  }\n  .mainimg {\n    width: 300px;\n  }\n  .car-bottom-heading {\n    width: fit-content;\n    margin: auto;\n    font-family: Luckiest Guy;\n    font-size: 60px;\n    font-weight: 500;\n    line-height: 60px;\n    // margin-left: 35%;\n\n    color: green;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
