// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  width: 90vw;
  justify-content: space-between;
  margin: 0 auto;
}
.footer .footer-top-box {
  display: flex;
  gap: 40px;
  padding: 0 20px;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  width: 100%;
  justify-content: space-evenly;
}
.footer .footer-top-box .container-footer {
  flex-direction: column;
  gap: 10px;
  height: -moz-fit-content;
  height: fit-content;
}
.footer .footer-top-box .container-footer .heading {
  color: #101010;
  font-family: Saira;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iCAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,eAAA;EACA,wBAAA;EAAA,mBAAA;EACA,cAAA;EACA,WAAA;EACA,6BAAA;AACJ;AACI;EACE,sBAAA;EACA,SAAA;EACA,wBAAA;EAAA,mBAAA;AACN;AACM;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACR","sourcesContent":[".footer {\n  display: flex;\n  flex-direction: column !important;\n  align-items: center;\n  width: 90vw;\n  justify-content: space-between;\n  margin: 0 auto;\n\n  .footer-top-box {\n    display: flex;\n    gap: 40px;\n    padding: 0 20px;\n    height: fit-content;\n    margin: 0 auto;\n    width: 100%;\n    justify-content: space-evenly;\n\n    .container-footer {\n      flex-direction: column;\n      gap: 10px;\n      height: fit-content;\n\n      .heading {\n        color: #101010;\n        font-family: Saira;\n        font-size: 24px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: normal;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
