// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .btn-get-details {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .btn-get-details:hover {
    background-color: #45a049;
    transform: scale(1.05);
  }
  
  .btn-get-details:active {
    background-color: #3e8e41;
    transform: scale(0.95);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/admin/stats/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB,EAAE,UAAU;IACrC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,2DAA2D;EAC7D;;EAEA;IACE,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,sBAAsB;EACxB","sourcesContent":[".admin-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 50px;\n  }\n  \n  .btn-get-details {\n    background-color: #4CAF50; /* Green */\n    border: none;\n    color: white;\n    padding: 15px 32px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 4px 2px;\n    cursor: pointer;\n    border-radius: 12px;\n    transition: background-color 0.3s ease, transform 0.3s ease;\n  }\n  \n  .btn-get-details:hover {\n    background-color: #45a049;\n    transform: scale(1.05);\n  }\n  \n  .btn-get-details:active {\n    background-color: #3e8e41;\n    transform: scale(0.95);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
