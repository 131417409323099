// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-container {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: #2cc248;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  transition: background-color 0.3s ease-in-out;
  z-index: 100;
}

.icon-container:hover {
  z-index: 100;
}
.icon {
  color: white;
  width: 30px !important;
  height: 30px !important;
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/components/icon/icon.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,6CAA6C;EAC7C,YAAY;AACd;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".icon-container {\n  width: 50px !important;\n  height: 50px !important;\n  border-radius: 50%;\n  background-color: #2cc248;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 10px;\n  transition: background-color 0.3s ease-in-out;\n  z-index: 100;\n}\n\n.icon-container:hover {\n  z-index: 100;\n}\n.icon {\n  color: white;\n  width: 30px !important;\n  height: 30px !important;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
