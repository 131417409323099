// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
}

#main {
  background-color: #2474d2;
  display: flex;
  flex-direction: row;
}
#main .event-hover-image {
  height: 300px;
  width: 500px;
}
#main .zone-hover-image {
  width: 450px;
}
#main img {
  display: block;
  height: 17rem;
  width: 20rem;
  padding: 2rem;
}
#main .zone-hover {
  padding: 2rem;
}
@media (max-width: 1300px) {
  #main .zone-hover {
    width: 30vw;
    margin: auto;
  }
}
@media (max-width: 462px) {
  #main .zone-hover {
    width: 63vh;
  }
}
#main .zone-hover .sub {
  color: #fff;
  font-family: Saira;
  font-size: 21px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.045em;
  margin-left: 2rem;
  margin-top: 1rem;
}
#main .zone-hover .sub2 {
  color: #fff;
  font-family: Saira;
  text-align: left;
}
@media (max-width: 1300px) {
  #main {
    flex-direction: column;
  }
  #main .sub {
    width: 12rem;
    display: block;
    margin: auto;
  }
  #main .sub2 {
    width: 14rem;
    display: block;
    margin: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/hover/first.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;AACF;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;AACF;AAEI;EAAA,aAAA;EAAA;AAAA;AAIA;EAAA;AAAA;AAGF;EACE,cAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAII;EAHF;IAII,WAAA;IACA,YAAA;EADJ;AACF;AAEI;EAPF;IAQI,WAAA;EACJ;AACF;AACI;EACE,WAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AACN;AAEI;EACE,WAAA;EACA,kBAAA;EACA,gBAAA;AAAN;AAIE;EAjDF;IAkDI,sBAAA;EADF;EAEE;IACE,YAAA;IACA,cAAA;IACA,YAAA;EAAJ;EAEE;IACE,YAAA;IACA,cAAA;IACA,YAAA;EAAJ;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n}\n\n#main {\n  background-color: #2474d2;\n  display: flex;\n  flex-direction: row;\n\n  .event-hover-image {\n    @apply w-[500px] h-[300px];\n  }\n\n  .zone-hover-image {\n    @apply w-[450px];\n  }\n\n  img {\n    display: block;\n    height: 17rem;\n    width: 20rem;\n    padding: 2rem;\n  }\n\n  .zone-hover {\n    padding: 2rem;\n\n    @media (max-width: 1300px) {\n      width: 30vw;\n      margin: auto;\n    }\n    @media (max-width: 462px) {\n      width: 63vh;\n    }\n\n    .sub {\n      color: #fff;\n      font-family: Saira;\n      font-size: 21px;\n      font-weight: 500;\n      text-align: left;\n      letter-spacing: 0.045em;\n      margin-left: 2rem;\n      margin-top: 1rem;\n    }\n\n    .sub2 {\n      color: #fff;\n      font-family: Saira;\n      text-align: left;\n    }\n  }\n\n  @media (max-width: 1300px) {\n    flex-direction: column;\n    .sub {\n      width: 12rem;\n      display: block;\n      margin: auto;\n    }\n    .sub2 {\n      width: 14rem;\n      display: block;\n      margin: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
