// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
}

#third {
  background-color: #2474d2;
  display: flex;
  flex-direction: row;
}
#third .corporate-hover-image {
  height: 300px;
  width: 450px;
}
#third img {
  display: block;
  height: 17rem;
  width: 20rem;
  padding: 2rem;
}
#third .zone-hover {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-items: left;
  justify-content: space-between;
}
#third .zone-hover .sub {
  color: #fff;
  font-family: Saira;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.045em;
  text-align: left;
}
#third .zone-hover .sub2 {
  margin-bottom: 5%;
  color: #fff;
  font-family: Saira;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/hover/third.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,UAAA;AACJ;;AAIE;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAMM;EAAA,aAAA;EAAA;AAAA;AAGF;EACE,cAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;AAJN;AASI;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;AAPN;AAWM;EACE,WAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AATR;AAaM;EACE,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AAXR","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n  }\n\n  \n  \n  #third {\n    background-color: #2474d2;\n    display: flex;\n    flex-direction: row;\n    //  width: 100%;\n   \n \n    .corporate-hover-image {\n      @apply w-[450px] h-[300px];\n    }\n  \n    img {\n      display: block;\n      height: 17rem;\n      width: 20rem;\n      padding: 2rem;\n  \n    \n    }\n  \n    .zone-hover {\n      padding: 2rem;\n      display: flex;\n      flex-direction: column;\n      justify-items: left;\n      justify-content: space-between;\n  \n   \n  \n      .sub {\n        color: #fff;\n        font-family: Saira;\n        font-size: 21px;\n        font-weight:500;\n        letter-spacing: 0.045em;\n        text-align: left;\n     \n      }\n  \n      .sub2 {\n        margin-bottom: 5%;\n        color: #fff;\n        font-family: Saira;\n        text-align: left;\n      }\n    }\n  \n\n    }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
