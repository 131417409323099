// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/careers.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
}

.careers .top {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center/cover;
}
.careers .heading {
  color: #fff;
  font-family: Luckiest Guy;
  font-size: 74.619px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 7.089px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px) {
  .careers .heading {
    font-size: 40px;
    font-weight: 400;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/careers/styles.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;AACF;;AAGE;EACE,gEAAA;AAAJ;AAIE;EACE,WAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAII;EAbF;IAcI,eAAA;IACA,gBAAA;EADJ;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n}\n\n.careers {\n  .top {\n    background: url('./images/careers.png') center/cover;\n    // margin-bottom: 0px;\n  }\n\n  .heading {\n    color: #fff;\n    font-family: Luckiest Guy;\n    font-size: 74.619px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    letter-spacing: 7.089px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    @media (max-width: 600px) {\n      font-size: 40px;\n      font-weight: 400;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
